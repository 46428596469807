<template>
  <v-row class="text-center p-4">
    <img
      class="w-[160px] h-[60px] mx-auto lg:mx-0"
      alt="Vue logo"
      src="../assets/logo.png"
    />
    <v-col class="flex justify-around gap-2 flex-wrap">
      <button
        class="bg-blue-900 text-orange-500 font-bold border-orange-500 rounded-md p-2 cursor-pointer transition duration-300"
        @click="emit('change', 'carousel')"
      >
        Projetos
      </button>
      <button
        class="bg-blue-900 text-orange-500 font-bold border border-orange-500 rounded-md p-2 cursor-pointer transition duration-300"
        @click="emit('change', 'sobre')"
      >
        Sobre
      </button>
      <button
        class="bg-blue-900 text-orange-500 font-bold border border-orange-500 rounded-md p-2 cursor-pointer transition duration-300"
        @click="emit('change', 'missao')"
        style="width: 280px"
      >
        Missão / Visão / Valores
      </button>
      <button
        class="bg-blue-900 text-orange-500 font-bold border border-orange-500 rounded-md p-2 cursor-pointer transition duration-300"
        @click="emit('change', 'contato')"
      >
        Contato
      </button>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "navBar",
  props: {
    msg: String,
  },
  setup(_, { emit }) {
    return { emit };
  },
};
</script>

<style scoped>
.border-orange-500 {
  border: 1px solid #ff8c00 !important;
  width: 180px;
}
</style>
