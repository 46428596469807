<template>
  <div>
    <Navbar @change="change" />
    <v-container>
      <v-row class="flex justify-center items-center">
        <div class="carousel-container mt-8 rounded-lg">
          <div
            class="carousel"
            id="carousel"
            :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
          >
            <div
              v-for="(item, i) in carouselItems"
              :key="i"
              class="carousel-item flex-column"
            >
              <img :src="item.src" :alt="item.name" class="carousel-image" />
              <p
                v-if="carouselItems[i]"
                class="text-xl font-black text-center inset-x-0 bottom-2"
                style="color: #232323"
              >
                {{ item.name }}
              </p>
            </div>
          </div>

          <v-btn
            density="default"
            icon="mdi-chevron-left"
            class="prev-button"
            @click="prevSlide"
          ></v-btn>
          <v-btn
            density="default"
            icon="mdi-chevron-right"
            class="next-button"
            @click="nextSlide"
          ></v-btn>
        </div>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p
            id="sobre"
            class="text-black font-bold mt-6 text-base"
            style="color: #232323"
          >
            Sobre
          </p>
          <ul class="text-sm lg:text-base list-disc mt-1">
            <p class="text-base" style="color: #232323">
              A AJP Engenharia fornece várias soluções para sua empresa
              envolvendo Engenharia Mecânica, Projetos Mecânicos e NR12.
            </p>
            <p class="font-bold text-base mt-2">
              Abaixo estão alguns dos serviços prestados:
            </p>

            <li class="text-base">Detalhamento de Projetos 2D;</li>
            <li class="text-base">Projetos 3D;</li>
            <li class="text-base">Levantamento de Projetos;</li>
            <li class="text-base">Lista de Materiais;</li>
            <li class="text-base">Simulação Estática de Elementos Finitos;</li>
            <li class="text-base">
              Documentação de Simulação dos Elementos Executados;
            </li>
            <li class="text-base">Manual de Manutenção;</li>
            <li class="text-base">
              ART de serviço e instalação de Engenharia Mecânica e NR12;
            </li>
          </ul>
          <p
            id="missao"
            class="font-bold mt-6 text-base"
            style="color: #232323"
          >
            Missão
          </p>
          <P style="color: #232323">
            Desenvolver serviço de alta qualidade com baixo custo para nossos
            clientes atendendo sua demanda no melhor tempo com ótima qualidade.
          </P>
          <p class="font-bold mt-6 text-base" style="color: #232323">Visão</p>
          <P>
            Ser uma empresa com bom relacionamento com seus clientes criando
            parcerias e ter reconhecimento nacional pelo serviço prestado.
          </P>
          <p class="font-bold mt-6" style="color: #232323">Valores</p>
          <P> Eficiência, Humanização, Respeito, Transparência e Qualidade. </P>
          <p
            id="contato"
            class="font-bold mt-6 text-base"
            style="color: #232323"
          >
            Contato
          </p>
          <p class="text-sm lg:text-base font-medium">
            <a href="mailto:engenhariaajp1@gmail.com" class="font-medium"
              ><v-icon class="pr-2"> mdi-email </v-icon
              >engenhariaajp1@gmail.com</a
            >
          </p>
          <p class="text-sm lg:text-base font-medium mt-2">
            <a
              href="https://wa.me/5511944619487"
              target="_blank"
              class="font-medium"
              ><v-icon class="pr-2"> mdi-whatsapp </v-icon>Tel/WhatsApp : (11)
              94461-9487</a
            >
          </p>
        </v-col>
        <v-col cols="4" sm="2">
          <img
            class="h-[200px] rounded-lg"
            alt="profile"
            src="./assets/profileajp.jpeg"
          />
        </v-col>
        <v-col cols="8" sm="10" class="sm:ml-[-55px]">
          <p class="sm:text-lg font-bold" style="color: #232323">
            Jackson Domingues - Gestor de projetos
          </p>
          <p class="mt-4 sm:text-lg" style="color: #232323">
            Formação Academica: MBA em Gestão de projetos,
          </p>
          <p class="mt-1 sm:text-lg" style="color: #232323">
            Engenheiro de Segurança do Trabalho (CREA Ativo)
          </p>
          <p class="mt-1 sm:text-lg" style="color: #232323">
            Engenheiro Mecânico (CREA Ativo)
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-btn
      v-show="showButton"
      class="fab-btn absolute right-4 mt-[-40px] rounded-2xl"
      @click="scrollToTop"
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
  </div>
</template>

<script>
import Navbar from "./components/navbar.vue";

import image1 from "./assets/BRACO-MECANICO-PARA-IHM.jpeg";
import image2 from "./assets/CAIXA-DE-AQUECIMENTO.jpeg";
import image3 from "./assets/CALIBRADOR-DE-FERRAMENTAS.jpeg";
import image4 from "./assets/CARRO-PARA-GANCHO-DE-LINHA-AUTOMOTIVA.jpeg";

// import image5 from "./assets/ESTEIRA-COM-NR12.jpeg";
// import image6 from "./assets/ESTEIRA-LATERAL.jpeg";
import image7 from "./assets/ESTRUTURA-MECANICA-DE-AQUECIMENTO.jpeg";
import image8 from "./assets/GANCHO-PARA-EIXO-DE-COMANDO.jpeg";
import image9 from "./assets/GANCHO-TOMBADOR.jpeg";
// import image10 from "./assets/NR12-DE-LINHA.jpeg";
import image11 from "./assets/NR12-ENCLAUSURAMENTO-DE-MAQUINA.jpeg";
import image12 from "./assets/NR12-MAQUINA-DE-BOBINA.jpeg";
import image13 from "./assets/NR12-TORNO-MECANICO.jpeg";
import image14 from "./assets/NR12.jpeg";
import image15 from "./assets/SIMULACAO-ESTATICA-COM-ELEMENTOS-FINITOS.jpeg";
import image16 from "./assets/CAIXA-DE-AQUECIMENTO-IMAGEM-SUPERIOR.jpeg";
import image18 from "./assets/NR12-LINHA-AUTOMOTIVA.jpeg";
import image19 from "./assets/NR12-BASE-ELEVATORIA.jpeg";
import image20 from "./assets/NR12-DE-PRENSA.jpeg";
import image21 from "./assets/NR12-IMPRESSORA.jpeg";
import image22 from "./assets/NR12-ROSQUEADEIRA.jpeg";

// ..........
import image17 from "./assets/ESTEIRA-LATERAL-DE-PRESSAO.png";

import { ref, onMounted, onBeforeUnmount } from "vue";

export default {
  name: "App",
  components: {
    Navbar,
  },
  setup() {
    const showButton = ref(false);
    const currentIndex = ref(0);
    const carouselItems = ref([
      {
        src: image1,
        name: "BRAÇO MECÂNICO PARA IHM",
      },
      {
        src: image2,
        name: "CAIXA DE AQUECIMENTO IMAGEM INFERIOR",
      },
      {
        src: image3,
        name: "CALIBRADOR DE FERRAMENTAS",
      },
      {
        src: image4,
        name: "CARRO PARA GANCHO DE LINHA AUTOMOTIVA",
      },
      // {
      //   src: image5,
      //   name: "ESTEIRA COM NR12",
      //
      // },
      // {
      //   src: image6,
      //   name: "ESTEIRA LATERAL",
      //
      // },
      {
        src: image7,
        name: "ESTRUTURA MECÂNICA DE AQUECIMENTO",
      },
      {
        src: image8,
        name: "GANCHO PARA EIXO DE COMANDO",
      },
      {
        src: image9,
        name: "GANCHO TOMBADOR",
      },
      // {
      //   src: image10,
      //   name: "NR12 DE LINHA",
      //
      // },
      {
        src: image11,
        name: "NR12 ENCLAUSURAMENTO DE MAQUINA",
      },
      {
        src: image12,
        name: "NR12 MAQUINA DE BOBINA",
      },
      {
        src: image13,
        name: "NR12 TORNO MECÂNICO",
      },
      {
        src: image14,
        name: "NR12",
      },
      {
        src: image15,
        name: "SIMULAÇÃO ESTATICA COM ELEMENTOS FINITOS ",
      },
      {
        src: image16,
        name: "CAIXA DE AQUECIMENTO IMAGEM SUPERIOR",
      },
      {
        src: image17,
        name: "ESTEIRA LATERAL DE PRESSÃO",
      },
      {
        src: image18,
        name: "NR12  LINHA AUTOMOTIVA",
      },
      {
        src: image19,
        name: "NR12 BASE ELEVATÓRIA",
      },
      {
        src: image20,
        name: "NR12 DE PRENSA",
      },
      {
        src: image21,
        name: "NR12 IMPRESSORA",
      },
      {
        src: image22,
        name: "NR12 ROSQUEADEIRA",
      },
    ]);
    function change(sectionId) {
      const sectionElement = document.getElementById(sectionId);

      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth" });
      }
    }
    function scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    function handleScroll() {
      showButton.value = window.scrollY > 500;
    }

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    function nextSlide() {
      if (currentIndex.value === carouselItems.value.length - 1) {
        currentIndex.value = 0;
      } else {
        currentIndex.value = currentIndex.value + 1;
      }
    }

    function prevSlide() {
      if (currentIndex.value === 0) {
        currentIndex.value = carouselItems.value.length - 1;
      } else {
        currentIndex.value = currentIndex.value - 1;
      }
    }
    return {
      carouselItems,
      nextSlide,
      prevSlide,
      currentIndex,
      showButton,
      change,
      scrollToTop,
    };
  },
};
</script>

<style scoped>
ul {
  color: #232323;
}
li {
  margin-left: 18px;
}
img {
  border-radius: 8px !important;
}
.carousel-container {
  position: relative;
  overflow: hidden;
}

.carousel {
  max-width: 800px;
  display: flex;
  transition: transform 0.5s ease-in-out;
  align-items: center;
}

.carousel-item {
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-image {
  width: 600px;
  height: 400px;
  border-radius: 8px;
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  font-size: 18px;
  cursor: pointer;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}
</style>
